import {useEffect} from 'react';
import {isClient} from 'common/utils';

export type EventListenerCallback<EventMap, EventType extends keyof EventMap> = (ev: EventMap[EventType]) => any;

export type WindowEventListenerCallback<EventType extends keyof WindowEventMap> = EventListenerCallback<
  WindowEventMap,
  EventType
>;

export const useEventListener = <EventMap, EventType extends keyof EventMap = keyof EventMap>(
  type: EventType,
  callback: EventListenerCallback<EventMap, EventType>,
  el: {
    addEventListener: (type: EventType, callback: EventListenerCallback<EventMap, EventType>) => any;
    removeEventListener: (type: EventType, callback: EventListenerCallback<EventMap, EventType>) => any;
  },
  deps?: ReadonlyArray<any>
) => {
  useEffect(() => {
    el.addEventListener(type, callback);

    return () => {
      el.removeEventListener(type, callback);
    };
  }, deps);
};

export const useWindowEventListener = <EventType extends keyof WindowEventMap>(
  type: EventType,
  callback: WindowEventListenerCallback<EventType>,
  deps?: ReadonlyArray<any>
) => {
  if (isClient) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener(type, callback, window, deps);
  }
};
