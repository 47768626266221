'use client';
import {FC, useRef, useState, useEffect} from 'react';
import {getEnv} from 'environment/public.environment';
import {useTranslation} from 'react-i18next';
import {signOut, useSession} from 'next-auth/react';
import {Button} from 'common/components/button';
import {createUseStyles} from 'react-jss';
import {useEndSession} from 'features/auth/hooks/use-end-session';
import {TimeUtil} from 'common/utils/time-util';
import {SignoutReason} from 'features/auth/signout-reason';
import {Heading, Modal} from '@digdir/designsystemet-react';

const useModalStyles = createUseStyles(
  {
    countdown: {
      fontWeight: '600',
    },
  },
  {name: 'IdleTimer'}
);

const ACTIVITY_EVENT_TYPES = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

export const IdleTimer: FC = () => {
  const TIMEOUT_MS = getEnv().idleTimer.timeoutMs + getEnv().idleTimer.timeoutPromptMs;
  const PROMPT_TIME_MS = getEnv().idleTimer.timeoutPromptMs;

  const [timerState, setTimerState] = useState<'disabled' | 'running' | 'idle'>('disabled');
  const lastActive = useRef<number>(0);
  const [remainingTimeMs, setRemainingTimeMs] = useState<number>(TIMEOUT_MS);

  const [dialogOpen, toggleDialogOpen] = useState<boolean>(false);

  const start = () => {
    lastActive.current = Date.now();
    setTimerState('running');
  };

  const reset = () => {
    toggleDialogOpen(false);
    lastActive.current = Date.now();
  };

  const endSession = useEndSession();

  const onIdle = () => {
    setTimerState('idle');
    toggleDialogOpen(false);
    endSession(SignoutReason.INACTIVE);
  };

  useEffect(() => {
    if (timerState === 'running') {
      const interval = setInterval(() => {
        const timePassed = Date.now() - lastActive.current;
        setRemainingTimeMs(TIMEOUT_MS - timePassed);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timerState, lastActive.current]);

  const {data: session, status: sessionStatus, update} = useSession();

  useEffect(() => {
    if (session?.error === 'TimeoutError') {
      signOut({redirect: false});
    }
  }, [session?.error]);

  if (session && sessionStatus === 'authenticated') {
    if (timerState === 'disabled') {
      start();
    } else if (timerState === 'running' && remainingTimeMs <= 0) {
      onIdle();
    } else if (remainingTimeMs <= PROMPT_TIME_MS && !dialogOpen) {
      toggleDialogOpen(true);
    } else if (remainingTimeMs > PROMPT_TIME_MS && dialogOpen) {
      toggleDialogOpen(false);
    }
  } else if (timerState !== 'disabled') {
    setTimerState('disabled');
    reset();
  }

  const onActive = async () => {
    lastActive.current = Date.now();

    const newSession = await update();
    if (newSession === null || newSession.error) {
      onIdle();
    }
  };

  useEffect(() => {
    if (timerState === 'running') {
      const onEvent = (e: Event) => {
        const timePassed = Date.now() - lastActive.current;
        if (timePassed > TIMEOUT_MS) {
          onIdle();
        } else if (timePassed >= getEnv().idleTimer.throttleMs && !dialogOpen && e.type !== 'visibilitychange') {
          onActive();
        }
      };

      ACTIVITY_EVENT_TYPES.forEach(eventType => document.addEventListener(eventType, onEvent));
      return () => {
        ACTIVITY_EVENT_TYPES.forEach(eventType => document.removeEventListener(eventType, onEvent));
      };
    }
  }, [timerState, dialogOpen]);

  const {t} = useTranslation(['common']);
  const styles = useModalStyles();

  if (remainingTimeMs > 0) {
    return (
      <Modal open={dialogOpen} onClose={onActive} data-testid='modal'>
        <Heading>{t('common:timeout.title')}</Heading>
        <p>
          {t('common:timeout.info')}
          <span className={styles.countdown}>
            {' '}
            {t('common:timeout.time-remaining', TimeUtil.msToMinutesAndSeconds(remainingTimeMs))}
          </span>
        </p>
        <div>
          <Button variant='primary' data-transaction-name='stay-signed-out' onClick={onActive}>
            {t('common:timeout.stay-btn')}
          </Button>
          <Button variant='secondary' data-transaction-name='sign-out' onClick={onIdle}>
            {t('common:session-header.signout')}
          </Button>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal open={dialogOpen} onClose={onIdle} data-testid='modal'>
        <Heading>{t('common:post-timeout.title')}</Heading>
        <p>{t('common:post-timeout.info')}</p>
        <div>
          <Button
            variant='primary'
            data-transaction-name='post-timeout-sign-back-in'
            data-testid='pt-signin'
            onClick={onActive}
          >
            {t('common:post-timeout.login-btn')}
          </Button>
          <Button
            variant='secondary'
            data-transaction-name='post-timeout-stay-signed-out'
            data-testid='pt-close'
            onClick={onIdle}
          >
            {t('common:post-timeout.close-btn')}
          </Button>
        </div>
      </Modal>
    );
  }
};
