'use client';

import {useSession} from 'next-auth/react';
import {createUseStyles} from 'react-jss';
import {colorPalette} from 'styles/colors';
import {useTranslation} from 'react-i18next';
import {SignoutReason} from 'features/auth/signout-reason';
import {FC} from 'react';

const useStyles = createUseStyles(
  theme => ({
    container: {
      backgroundColor: '#14222b',
      height: '2em',
      minHeight: '40px',

      '@media print': {
        display: 'none',
      },
    },
    inner: {
      width: '100%',
      maxWidth: '1140px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    name: {
      color: colorPalette.grey05,
      fontStyle: 'italic',
      margin: '0.25em 0.75em',
      paddingTop: '1px',
      verticalAlign: 'middle',
    },
    signout: {
      background: 'white',
      color: 'black',
      fontSize: '0.75em',
      borderRadius: theme.borderRadius,
      height: '2em',
      margin: '0.5rem',
      padding: '0.25em 1em',
      flexShrink: 0,
      border: 'none',
      lineHeight: '1em',
    },
  }),
  {name: 'SessionHeader'}
);

export const SessionHeader: FC<{name: string}> = ({name}) => {
  const styles = useStyles();
  const {t} = useTranslation(['common']);
  const {data: session} = useSession();

  const endSession = (reason?: SignoutReason) => {
    const query = new URLSearchParams({
      postLogoutUrl: window.location.href.replace(window.location.origin, ''),
    });

    if (reason && reason !== SignoutReason.DEFAULT) {
      query.set('signout', reason);
    }

    window.location.assign(`/api/logout?${query.toString()}`);
  };

  if (!session?.user) {
    return null;
  }

  return (
    <header className={styles.container}>
      <div className={styles.inner}>
        <span data-testid='sessionName' className={styles.name}>
          {name}
        </span>
        <button
          data-transaction-name='sign-out'
          id='signoutBtn'
          data-testid='signoutBtn'
          className={styles.signout}
          onClick={() => endSession()}
        >
          {t('common:session-header.signout')}
        </button>
      </div>
    </header>
  );
};
