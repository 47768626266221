'use client';
import {getEnv} from 'environment/public.environment';
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {isClient, isServer} from 'common/utils';
import {useLang} from 'features/i18n/use-lang';
import {initReactI18next, useTranslation} from 'react-i18next';
import {FC, useEffect} from 'react';
import {getI18NOptions} from 'features/i18n/options';

export const initI18N = (lng: string) => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend((lang: string, ns: string) => import(`./locales/${lang}/${ns}.json`)))
    .init({
      ...getI18NOptions(),
      lng,
      preload: isServer ? getEnv().language.supported : [],
    });
};

export const I18N: FC = () => {
  const lang = useLang();

  if (!i18next.isInitialized) {
    initI18N(lang);
  }

  const {i18n} = useTranslation();

  if (isServer) {
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    if (isClient) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return null;
};
