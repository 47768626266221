import(/* webpackMode: "eager" */ "/opt/app-root/node_modules/@digdir/designsystemet-theme/brand/digdir.css");
;
import(/* webpackMode: "eager" */ "/opt/app-root/node_modules/@digdir/designsystemet-css/dist/src/index.css");
;
import(/* webpackMode: "eager" */ "/opt/app-root/node_modules/@brreg/designsystemet-theme/css/brreg.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/app-root/src/app/[lang]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IdleTimer"] */ "/opt/app-root/src/features/auth/components/idle-timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostTimeoutModal"] */ "/opt/app-root/src/features/auth/components/post-timeout-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RemoteSignoutModal"] */ "/opt/app-root/src/features/auth/components/remote-signout-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionHeader"] */ "/opt/app-root/src/features/auth/components/session-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JssRegistry"] */ "/opt/app-root/src/styles/jss-registry.tsx");
