'use client';

import {useWindowEventListener} from 'common/hooks/use-event-listener';
import {EventHandler, LangEvent} from 'common/types/events';
import {isClient} from 'common/utils';
import {useLang} from 'features/i18n/use-lang';
import {FC, createContext, useContext, useEffect} from 'react';

declare global {
  interface Window {
    brInit: () => void;
  }
}

let brvevenInited = false;
if (isClient) {
  window.removeEventListener('load', window.brInit);
}

export const langChangeContext = createContext<{onChange?: EventHandler<LangEvent>}>({
  onChange: undefined,
});

export const BrvevenClient: FC = () => {
  const lang = useLang();
  const langCtx = useContext(langChangeContext);

  useEffect(() => {
    if (!brvevenInited) {
      window.brInit?.();
      brvevenInited = true;
    }
  }, []);

  useWindowEventListener(
    'brreg:lang-change',
    ev => {
      if (lang !== ev.language) {
        setTimeout(() => {
          if (langCtx.onChange) {
            langCtx.onChange(ev);
          } else {
            const path = window.location.pathname.split('/');
            path[1] = ev.language;

            const newLocation = new URL(window.location.href);
            newLocation.pathname = path.join('/');

            window.location.assign(newLocation);
          }
        }, 600);
      }
      // }
    },
    [lang]
  );

  return null;
};
