'use client';

import {isClient} from 'common/utils';
import {Handlers} from 'common/utils/handlers';
import {PublicEnv, setClientEnv} from 'environment/public.environment';
import {BrvevenClient} from 'features/brveven/client';
import {I18N} from 'features/i18n/client';
import {ConceptID} from 'features/terms/felles-data-katalog/concept-ids';
import {ConceptDefinition} from 'features/terms/felles-data-katalog/model';
import {TermDescriptionProvider} from 'features/terms/term-provider';
import {Session} from 'next-auth';
import {SessionProvider, signOut} from 'next-auth/react';
import {useSearchParams} from 'next/navigation';
import {FC, ReactNode, useEffect} from 'react';

export const Providers: FC<{
  children: ReactNode;
  env: PublicEnv;
  session: Session | null;
  conceptDefinitions: Partial<Record<ConceptID, ConceptDefinition>>;
}> = ({children, env, session, conceptDefinitions}) => {
  setClientEnv(env);

  const query = useSearchParams();

  useEffect(() => {
    document.querySelector('main')?.addEventListener('copy', Handlers.onCopy);
  }, []);

  if (isClient && query?.get('signout') === '1') {
    const url = new URL(window.location.href);
    url.searchParams.delete('signout');
    signOut({callbackUrl: url.toString()});
    return null;
  }

  return (
    <>
      <SessionProvider session={session} refetchOnWindowFocus={false}>
        <TermDescriptionProvider conceptDefinitions={conceptDefinitions}>
          <BrvevenClient />
          <I18N />
          {children}
        </TermDescriptionProvider>
      </SessionProvider>
    </>
  );
};
