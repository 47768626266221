'use client';

import React, {FC, ReactElement} from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
  icon: {
    transform: 'scale(1.35)',
    margin: '-3px 0',
  },
});

type IconProps = React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>;
export type IconBase = React.ForwardRefExoticComponent<IconProps>;

export const Icon: FC<{icon: IconBase | ReactElement} & IconProps> = ({
  icon: IconComponent,
  className,
  ...passedProps
}) => {
  const styles = useStyles();

  const props = {
    ...passedProps,
    'aria-hidden': !passedProps['aria-label'],
    className: `${styles.icon}${className ? ` ${className}` : ''}`,
  };

  if (React.isValidElement(IconComponent)) {
    return <>{React.cloneElement(IconComponent, props)}</>;
  }
  return <IconComponent {...props} />;
};
